@tailwind base;
@tailwind components;
@tailwind utilities;


@keyframes spin-reverse {
  0% { transform: rotate(360deg); }
  100% { transform: rotate(0deg); }
}

@keyframes pulse {
  0%, 100% { opacity: 1; }
  50% { opacity: 0.5; }
}

.animate-pulse {
  animation: pulse 1.5s infinite;
}

@layer components {
  .clip-path-custom {
    clip-path: polygon(0 0, 100% 0, 50% 80%, 80% 100%, 20% 100%, 0 80%);
  }
}