@import '~slick-carousel/slick/slick.css';
@import '~slick-carousel/slick/slick-theme.css';

@keyframes move1 {
  0% { transform: translate(0, 0); }
  25% { transform: translate(-10px, -10px); }
  50% { transform: translate(10px, -10px); }
  75% { transform: translate(-10px, 10px); }
  100% { transform: translate(0, 0); }
}

@keyframes move2 {
  0% { transform: translate(0, 0); }
  25% { transform: translate(20px, 10px); }
  50% { transform: translate(-10px, 20px); }
  75% { transform: translate(15px, -15px); }
  100% { transform: translate(0, 0); }
}

/* Additional animations */
@keyframes move3 {
  0% { transform: translate(0, 0); }
  25% { transform: translate(-15px, 10px); }
  50% { transform: translate(10px, -15px); }
  75% { transform: translate(-20px, 15px); }
  100% { transform: translate(0, 0); }
}

/* Add more keyframes as needed */

.move1 {
  animation: move1 6s ease-in-out infinite;
}

.move2 {
  animation: move2 7s ease-in-out infinite;
}

.move3 {
  animation: move3 8s ease-in-out infinite;
}
.move4 {
  animation: move1 8s ease-in-out infinite;
}
.move5 {
  animation: move2 8s ease-in-out infinite;
}
.move6 {
  animation: move3 8s ease-in-out infinite;
}

.move7 {
  animation: move1 8s ease-in-out infinite;
}
.move8 {
  animation: move2 8s ease-in-out infinite;
}
.move9 {
  animation: move3 8s ease-in-out infinite;
}
.move10 {
  animation: move1 8s ease-in-out infinite;
}
.move11 {
  animation: move2 8s ease-in-out infinite;
}
.move12 {
  animation: move3 8s ease-in-out infinite;
}
.move13 {
  animation: move1 8s ease-in-out infinite;
}
.move14 {
  animation: move2 8s ease-in-out infinite;
}


/* Apply similar styles for other moves */

/* Clip Path for the card shape */
.clip-card-shape {
  clip-path: polygon(0 0, 100% 0%, 100% 85%, 0% 100%);
}

/* Hover effect for background fill */
.group .service-card-container::before {
  content: '';
  position: absolute;
  inset: 0;
  background-color: #e60000; /* Background fill color */
  transform: scaleY(0);
  transform-origin: bottom;
  transition: transform 0.5s ease-out;
  z-index: 1;
}

/* Scale the background fill on hover */
.group:hover .service-card-container::before {
  transform: scaleY(1);
}

/* Circle color change on hover */
.group:hover .circle-1,
.group:hover .circle-2 {
  background-color: white !important;
}

/* Ensure circles are on top */
.circle-1,
.circle-2 {
  z-index: 2; /* Ensure circles appear on top */
}

@keyframes spin-slow {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@keyframes spin-reverse {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(-360deg);
  }
}

.rotating-bg {
  position: relative;
}

.rotating-circle {
  top: 8%;
  left: 5%;
  transform: translate(-50%, -50%);
  animation: move3 8s ease-in-out infinite;
}

.rotating-circle-2 {
  bottom: 40%;
  right:  25%;
  transform: translate(50%, 50%);
  animation: move3 8s ease-in-out infinite;
  /* animation: spin-reverse 30s linear infinite; */
}

.rotating-img {
 
  animation: spin-slow 15s linear infinite;
}

/* CSS for spinning animations */
@keyframes spin-slow {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@keyframes spin-reverse {
  0% {
    transform: rotate(360deg);
  }
  100% {
    transform: rotate(0deg);
  }
}

.rotating-bg .rotating-circle {
  animation: move3 8s ease-in-out infinite;
}



.rotating-bg .rotating-img {
  animation: spin-slow 30s linear infinite;
}
* {
  font-family: 'Poppins', sans-serif;
}
.no-scroll {
  overflow: hidden;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}




